import React from 'react';

interface IPaginationProps {}

const Pagination: React.FC<IPaginationProps> = (props) => {
  return (
    <div
      className="pagination-luckybuy-mint absolute !bottom-3 z-20 flex w-full justify-end gap-6 !pr-3 sm:!bottom-4 sm:!pr-4 md:!bottom-5 md:!pr-5 "
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <style jsx>{`
        div :global(.swiper-pagination-bullet) {
          background: #cbd5e0;
          width: 40px;
          height: 6px;
          border-radius: 6px;
          cursor: pointer;
        }
        div :global(.swiper-pagination-bullet-active) {
          background: #fff;
          width: 40px;
          height: 6px;
          border-radius: 6px;
        }
      `}</style>
    </div>
  );
};

export default Pagination;
