import React from 'react';
import { BannerConfig } from 'api/banner/banner_list';

interface IItemProps {
  data: BannerConfig;
}

const Item: React.FC<IItemProps> = ({ data }) => {
  return (
    <a
      href={data.link}
      target="_blank"
      className="relative flex w-full items-end font-rubik lg:justify-center"
      rel="noreferrer"
    >
      <div
        style={{ backgroundImage: `url("${data.img_url}")` }}
        className="absolute left-0 top-0 -z-20 h-full w-full rounded-2xl bg-cover bg-center bg-no-repeat content-[''] "
      ></div>
      <div className="relative z-10 h-[260px] w-full lg:h-[320px] ">
        <div className="bannerMask absolute left-0 top-0 -z-10 h-full w-full rounded-2xl "></div>
        <div className="absolute bottom-3 left-0 flex md:bottom-0">
          <div className="w-[calc(100vw-48px)] p-3 sm:max-w-[600px] sm:px-4 md:ml-8 md:py-5 lg:ml-10">
            <p className="z-40 mb-2 truncate text-[32px] font-bold leading-10 !text-white opacity-100 sm:text-[40px] sm:font-semibold sm:leading-[48px]">
              {data.name}
            </p>
            <p className="z-10 text-base !font-normal leading-6 !text-white opacity-70 line-clamp-1 sm:max-w-[400px] sm:text-xl sm:leading-7 md:max-w-[440px] lg:line-clamp-2">
              {data.content}
            </p>
          </div>
        </div>
      </div>
    </a>
  );
};

export default Item;
