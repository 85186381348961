import { errMsg } from '@/components/PageElement/ErrorMessage';
import jsCookie from 'js-cookie';
import request from 'lib/request';
import { useCallback } from 'react';
import {
  setRaffleListInfo,
  setScrollLoading,
} from 'store/features/raffleListSlice';
import { useAppDispatch, useAppSelector } from 'store/hooks';

import { List } from './raffle_list_me';
import { clearLocalData } from 'lib/resetData';

export interface Response {
  code: number;
  data: RaffleList;
  msg: string;
}

export interface RaffleList {
  has_next: boolean;
  list: List[];
  page: number;
  size: number;
  total: number;
}

export async function getRaffleList(
  community_type: string,
  project_type: string,
  sort_type: string,
  free_mint: string,
  my_partial_guild_ids: string,
  my_particial_project_ids: string,
  key_words: string,
  page: number
) {
  const res = await request<Response>('/raffle/list', {
    params: {
      community_type,
      project_type,
      sort_type,
      free_mint,
      my_partial_guild_ids,
      my_particial_project_ids,
      key_words,
      page,
      size: 20,
    },
  });
  if (res?.code === -2) {
    errMsg(res?.msg);
  } else if (res?.code === 2001 || res?.code === 2002) {
    errMsg('Account timeout, please reconnect your wallet address');
    const key = process.env.NEXT_PUBLIC_AUTHORIZATION_KEY;
    jsCookie.remove(key);
    localStorage.removeItem(
      `${localStorage.getItem('address') as string}-token`
    );
    clearLocalData();
    setTimeout(() => {
      location.reload();
    }, 500);
  }
  return res?.data;
}

export function useRaffleList() {
  const dispatch = useAppDispatch();
  const {
    communityType,
    projectType,
    sortType,
    freeMint,
    myPartialCampaignIds,
    searchName,
    raffleListCurPage,
    raffleListInfo: { has_next },
  } = useAppSelector((state) => state.raffleList);
  const loadRaffleList = useCallback(async () => {
    if (raffleListCurPage === 1 || !has_next) {
      return;
    }
    const res = await getRaffleList(
      communityType,
      projectType,
      sortType,
      freeMint,
      myPartialCampaignIds?.join(','),
      myPartialCampaignIds?.join(','),
      searchName,
      raffleListCurPage
    );
    dispatch(setScrollLoading(false));
    if (res && res?.page === raffleListCurPage) {
      dispatch(setRaffleListInfo(res));
    } else {
      dispatch(
        setRaffleListInfo({
          list: [],
          has_next: false,
          page: 1,
          size: 20,
          total: -1,
        })
      );
    }
  }, [
    communityType,
    dispatch,
    freeMint,
    has_next,
    myPartialCampaignIds,
    projectType,
    raffleListCurPage,
    searchName,
    sortType,
  ]);

  return loadRaffleList;
}
