import React, { useEffect } from 'react';
import { useAsyncEffect } from 'ahooks';

import { useAppDispatch, useAppSelector } from 'store/hooks';
import { getRaffleList } from 'api/raffle/raffle_list';
import {
  setCommunityType,
  setFreeMint,
  setLoading,
  setMyPartialCampaignIds,
  setMyPartialProjectIds,
  setProjectType,
  setRaffleListInfo,
  setSortType,
} from 'store/features/raffleListSlice';
import FreeMintContent from './FreeMintContent';
import SortByContent from './SortByContent';
import CommunityFilterContent from './CommunityFilterContent';
import Search from './Search';
import Options from './Options';
import ProjectFilterContent from './ProjectFilterContent';

const FilterLists = () => {
  const dispatch = useAppDispatch();
  const {
    raffleListCurPage,
    isChangeRaffleFilter,
    communityType,
    projectType,
    sortType,
    freeMint,
    myPartialCampaignIds,
    myParticialProjectIds,
    searchName,
    campaignList,
    projectList,
  } = useAppSelector((state) => state.raffleList);

  useEffect(() => {
    if (!!localStorage.getItem('FreeMint')) {
      dispatch(setFreeMint(localStorage.getItem('FreeMint') as string));
    } else {
      localStorage.setItem('FreeMint', '0');
      dispatch(setFreeMint('0'));
    }

    if (!!localStorage.getItem('Filter')) {
      if (localStorage.getItem('Filter') === 'my_partial') {
        const partialIds = (localStorage.getItem('MyPartial') as string)
          .split(',')
          .filter(
            (itemM) =>
              campaignList.findIndex(
                (itemC) => itemC.guild_id.toString() === itemM
              ) !== -1
          );
        if (partialIds?.length === 0) {
          localStorage.setItem('Filter', 'All');
          dispatch(setCommunityType('All'));
          localStorage.removeItem('MyPartial');
        } else {
          dispatch(setCommunityType('my_partial'));
          dispatch(setMyPartialCampaignIds(partialIds));
          localStorage.setItem('MyPartial', partialIds?.join(','));
        }
      } else {
        dispatch(setCommunityType(localStorage.getItem('Filter') as string));
      }
    } else {
      localStorage.setItem('Filter', 'All');
      dispatch(setCommunityType('All'));
    }

    if (!!localStorage.getItem('FilterProject')) {
      if (localStorage.getItem('FilterProject') === 'my_partial') {
        const partialProjectIds = (
          localStorage.getItem('MyPartialProject') as string
        )
          .split(',')
          .filter(
            (itemM) =>
              projectList.findIndex(
                (itemC) => itemC.id.toString() === itemM
              ) !== -1
          );
        if (partialProjectIds?.length === 0) {
          localStorage.setItem('FilterProject', 'All');
          dispatch(setProjectType('All'));
          localStorage.removeItem('MyPartialProject');
        } else {
          dispatch(setProjectType('my_partial'));
          dispatch(setMyPartialProjectIds(partialProjectIds));
          localStorage.setItem(
            'MyPartialProject',
            partialProjectIds?.join(',')
          );
        }
      } else {
        dispatch(
          setProjectType(localStorage.getItem('FilterProject') as string)
        );
      }
    } else {
      localStorage.setItem('FilterProject', 'All');
      dispatch(setProjectType('All'));
    }

    if (!!localStorage.getItem('SortBy')) {
      dispatch(setSortType(localStorage.getItem('SortBy') as string));
    } else {
      localStorage.setItem('SortBy', 'Trending');
      dispatch(setSortType('Trending'));
    }
  }, [campaignList, dispatch, projectList]);

  useAsyncEffect(async () => {
    if (
      raffleListCurPage === 1 &&
      communityType !== '' &&
      projectType !== '' &&
      sortType !== '' &&
      freeMint !== ''
    ) {
      dispatch(setLoading(true));
      const res = await getRaffleList(
        communityType,
        projectType,
        sortType,
        freeMint,
        myPartialCampaignIds?.join(','),
        myParticialProjectIds?.join(','),
        searchName,
        1
      );
      dispatch(setRaffleListInfo(res));
      dispatch(setLoading(false));
    }
  }, [
    communityType,
    projectType,
    freeMint,
    raffleListCurPage,
    sortType,
    myPartialCampaignIds,
    myParticialProjectIds,
    searchName,
    isChangeRaffleFilter,
  ]);

  return (
    <div id="filter">
      <div className="grid grid-cols-1 gap-5 md:flex md:items-center md:justify-between">
        <Search />
        <div className="flex items-center justify-between gap-12 md:justify-end md:gap-5 lg:gap-6">
          <div className="flex items-center gap-5 lg:gap-6">
            <CommunityFilterContent />
            <ProjectFilterContent />
            <SortByContent />
          </div>
          <FreeMintContent />
        </div>
      </div>
      <Options />
    </div>
  );
};

export default FilterLists;
