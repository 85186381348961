import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination as PaginationModule, Autoplay } from 'swiper';

import 'swiper/css';
import 'swiper/css/pagination';

import Item from './Item';
import Pagination from './Pagination';
import { BannerConfig } from 'api/banner/banner_list';

interface IBannerProps {
  data: BannerConfig[];
}

const Banner: React.FC<IBannerProps> = ({ data }) => {
  if (data?.length === 0 || !data) {
    return null;
  }
  return (
    <Swiper
      className="relative mb-8 w-full"
      modules={[PaginationModule, Autoplay]}
      pagination={{ clickable: true, el: '.pagination-luckybuy-mint' }}
      autoHeight={true}
      autoplay={{ delay: 5000 }}
    >
      {data?.map((item, index) => (
        <SwiperSlide key={index} className="flex">
          <Item data={item} />
        </SwiperSlide>
      ))}
      <Pagination />
    </Swiper>
  );
};

export default Banner;
