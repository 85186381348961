import { useCallback, useEffect, useMemo, useRef } from 'react';
import { GetServerSideProps, GetServerSidePropsContext } from 'next';
import { useInViewport, useThrottleEffect, useUpdateEffect } from 'ahooks';

import Banner from '@/components/Raffle/Banner';
import { event } from 'api/event';
import emptyIcon from 'assets/icons/myraffles/empty.png';

import { getBannerList, BannerConfig } from 'api/banner/banner_list';
import { useUserInfo } from 'lib/user';
import CardR from '@/components/Card/R';
import { useAppDispatch, useAppSelector } from 'store/hooks';

import {
  setCampaignList,
  setProjectList,
  setRaffleListCurPage,
  setScrollLoading,
} from 'store/features/raffleListSlice';
import { useRaffleList } from 'api/raffle/raffle_list';
import PageLayout from '@/components/PageLayout';
import {
  CampaignList,
  campaignListMe,
} from 'api/community/campaign_list_dc_me';
import FilterLists from '@/components/Raffle/FilterLists';
import {
  getTrackingUnmintProject,
  ProjectList,
} from 'api/project/project_tracking_unmint';
import SkeletonCardR from '@/components/Card/R/SkeletonCardR';

export const getServerSideProps: GetServerSideProps = async (
  ctx: GetServerSidePropsContext
) => {
  const res = await Promise.all([
    getBannerList(),
    campaignListMe(ctx.req?.cookies?.Authorization as string),
    getTrackingUnmintProject(ctx.req?.cookies?.Authorization as string),
  ]);
  const bannerData = res[0];
  const campaignList = res[1];
  const projectList = res[2];
  return {
    props: {
      bannerData,
      campaignList,
      projectList,
    },
  };
};

interface LuckybuyMintPageType {
  bannerData: BannerConfig[];
  campaignList: CampaignList[];
  projectList: ProjectList[];
}

export default function RafflePage(props: LuckybuyMintPageType) {
  const { bannerData, campaignList, projectList } = props;
  const dispatch = useAppDispatch();
  const { address } = useUserInfo();
  const requestStatus = useRef(true);
  const refleshStatus = useRef(0);
  const inViewportRef = useRef(null);
  const [inViewport] = useInViewport(inViewportRef);
  const {
    raffleListInfo: { has_next, list, page, total },
    raffleListCurPage,
    loading,
    scrollLoading,
  } = useAppSelector((state) => state.raffleList);
  const loadRaffleList = useRaffleList();

  useMemo(() => {
    dispatch(setCampaignList(campaignList));
    dispatch(setProjectList(projectList));
  }, [campaignList, dispatch, projectList]);

  useEffect(() => {
    event({
      event: 'page_view',
      uid: localStorage.getItem('uuid'),
      address: address,
      biz: 'Luckyplus',
      page: 'raffle',
      timestamp: Date.now(),
      extra4: localStorage.getItem('uuid'),
      extra5: location.href,
    });
  }, [address]);

  useThrottleEffect(
    () => {
      if (
        has_next &&
        requestStatus.current &&
        page * 20 < total &&
        total !== -1 &&
        inViewport
      ) {
        dispatch(setRaffleListCurPage(raffleListCurPage + 1));
        requestStatus.current = false;
        refleshStatus.current = new Date().getTime();
      }
    },
    [
      has_next,
      dispatch,
      raffleListCurPage,
      requestStatus.current,
      total,
      page,
      inViewport,
    ],
    {
      wait: 50,
    }
  );

  useUpdateEffect(() => {
    requestStatus.current = true;
  }, [page]);

  useUpdateEffect(() => {
    loadRaffleList();
    dispatch(setScrollLoading(true));
  }, [refleshStatus.current]);

  const raffleListRender = useCallback(() => {
    if (loading) {
      return (
        <div className="mt-6 grid grid-cols-1 gap-6  md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 3xl:grid-cols-5">
          {Array.from({ length: 20 }, (_, index) => index + 1)?.map((index) => (
            <SkeletonCardR key={index} />
          ))}
        </div>
      );
    }
    if (total === -1) {
      return null;
    }
    if (list?.length === 0) {
      return (
        <div className="mt-8 flex items-center justify-center gap-2 py-[120px]">
          <img src={emptyIcon.src} className="w-6" alt="" />
          <div className="text-base font-normal text-[#FCFCFD]">
            It looks kinda empty here
          </div>
        </div>
      );
    } else {
      return (
        <div>
          <div className="mt-6 grid grid-cols-1 gap-6  md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 3xl:grid-cols-5">
            {list?.map((item) => (
              <CardR
                key={item?.vanity_url}
                vanity_url={item?.vanity_url}
                name={item?.name as string}
                campaign={item?.campaign}
                winner={item?.winner_count}
                status={item?.status}
                myentry={item?.my_entry}
                project={item?.project}
                whitelist_method={item?.whitelist_method}
                type={item?.type}
                endTime={item?.end_time as number}
                remainingSeconds={item?.remaining_seconds as number}
                blockchain={item?.project?.blockchain}
              />
            ))}
          </div>
          <div className="h-[0.1px]" ref={inViewportRef}></div>
          {scrollLoading && (
            <div className="mt-6 grid grid-cols-1 gap-6  md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 3xl:grid-cols-5">
              {Array.from({ length: 20 }, (_, index) => index + 1)?.map(
                (index) => (
                  <SkeletonCardR key={index} />
                )
              )}
            </div>
          )}
        </div>
      );
    }
  }, [list, loading, scrollLoading, total]);

  return (
    <PageLayout full>
      <div className="h-full">
        <Banner data={bannerData} />
        <FilterLists />
        {raffleListRender()}
      </div>
    </PageLayout>
  );
}
