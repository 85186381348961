import React, { useCallback } from 'react';
import classNames from 'classnames';
import {
  setChangeRaffleFilter,
  setFreeMint,
  setRaffleListCurPage,
  setRaffleListInfo,
} from 'store/features/raffleListSlice';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  CheckLine,
} from 'lib/lucky-x-icons';

const FreeMintContent = () => {
  const dispatch = useAppDispatch();
  const { freeMint } = useAppSelector((state) => state.raffleList);

  const handleSelectFreeMint = useCallback(() => {
    dispatch(setRaffleListCurPage(1));
    dispatch(
      setRaffleListInfo({
        has_next: false,
        list: [],
        page: 1,
        size: 20,
        total: -1,
      })
    );
    dispatch(setFreeMint(freeMint === '1' ? '0' : '1'));
    localStorage.setItem('FreeMint', freeMint === '1' ? '0' : '1');
    dispatch(setChangeRaffleFilter(new Date().getTime()));
  }, [dispatch, freeMint]);

  return (
    <div
      className={classNames(
        'group h-10 w-[122px] cursor-pointer rounded-full  p-[1px] text-base font-normal ',
        {
          'border border-neutral-600 text-neutral-500 hover:border-neutral-100':
            freeMint === '0',
          'automateBg text-neutral-100': freeMint === '1',
        }
      )}
      onClick={handleSelectFreeMint}
    >
      <div
        className={classNames(
          'flex h-full w-full items-center justify-center gap-2',
          {
            ' rounded-full bg-neutral-1000 ': freeMint === '1',
          }
        )}
      >
        <div
          className={classNames(
            'flex h-4 w-4 items-center justify-center rounded-full',
            {
              automateBg: freeMint === '1',
              'border border-neutral-500 group-hover:border-neutral-100':
                freeMint === '0',
            }
          )}
        >
          {freeMint === '1' && (
            <CheckLine size={16} className="text-neutral-500" />
          )}
        </div>
        <div className="text-neutral-100">FreeMint</div>
      </div>
    </div>
  );
};

export default FreeMintContent;
