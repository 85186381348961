import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  useClickAway,
  useScroll,
  useSize,
  useToggle,
  useUpdateEffect,
} from 'ahooks';
import classNames from 'classnames';

import {
  setChangeRaffleFilter,
  setCommunityType,
  setMyPartialCampaignIds,
  setRaffleListCurPage,
  setRaffleListInfo,
} from 'store/features/raffleListSlice';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { useUserInfo } from 'lib/user';
import { UsersProfiles01 } from 'lib/lucky-x-icons-2';
import { CheckLine } from 'lib/lucky-x-icons';

const filterOptions = [
  {
    id: 1,
    text: 'All communities',
    tag: 'All',
  },
  {
    id: 2,
    text: 'My communities',
    tag: 'My',
  },
];

const CommunityFilterContent = () => {
  const dispatch = useAppDispatch();
  const { isLogin, discord_username } = useUserInfo();
  const { communityType, campaignList, myPartialCampaignIds } = useAppSelector(
    (state) => state.raffleList
  );
  const { screenWidth } = useAppSelector((state) => state.nav);
  const [openFilter, { toggle: toggleFilter, setLeft: setCloseFilter }] =
    useToggle(false);
  const [clickFilter, setClickFilter] = useState(false);
  const filterRef = useRef<HTMLDivElement>(null);

  const filterListRef = useRef<HTMLDivElement>(null);
  const filterListScroll = useScroll(filterListRef);
  const contentRef = useRef<HTMLDivElement>(null);
  const contentSize = useSize(contentRef);

  const [isScrollFilterList, setIsScrollFilterList] = useState(false);

  useClickAway(() => {
    setCloseFilter();
    setClickFilter(false);
  }, filterRef);

  const scrollPercentage = useMemo(() => {
    if (
      screenWidth >= 768
        ? Number(contentSize?.height) < 494
        : Number(contentSize?.height) < 300
    ) {
      return;
    }
    return (
      Number(filterListScroll?.top) /
      (Number(contentSize?.height) - (screenWidth >= 768 ? 492 : 298))
    );
  }, [contentSize?.height, filterListScroll?.top, screenWidth]);

  useEffect(() => {
    if (filterListScroll?.top !== 0) {
      setIsScrollFilterList(true);
    }
  }, [filterListScroll?.top]);

  useUpdateEffect(() => {
    if (isScrollFilterList) {
      setTimeout(() => {
        setIsScrollFilterList(false);
      }, 1000);
    }
  }, [isScrollFilterList]);

  const clearData = useCallback(() => {
    dispatch(setRaffleListCurPage(1));
    dispatch(
      setRaffleListInfo({
        has_next: false,
        list: [],
        page: 1,
        size: 20,
        total: -1,
      })
    );
    setClickFilter(false);
    dispatch(setChangeRaffleFilter(new Date().getTime()));
  }, [dispatch]);

  const handleSelectFilter = useCallback(
    (tag: string) => {
      if ((!isLogin || !discord_username) && tag === 'My') {
        return;
      }
      if (tag !== communityType) {
        clearData();
        dispatch(setMyPartialCampaignIds([]));
        dispatch(setCommunityType(tag));
        localStorage.setItem('Filter', tag);
        setCloseFilter();
      }
    },
    [
      clearData,
      communityType,
      discord_username,
      dispatch,
      isLogin,
      setCloseFilter,
    ]
  );

  const handleSelectCommunityList = useCallback(
    (id: string) => {
      dispatch(setCommunityType('my_partial'));
      clearData();
      localStorage.setItem('Filter', 'my_partial');
      if (myPartialCampaignIds.findIndex((itemC) => itemC === id) === -1) {
        dispatch(setMyPartialCampaignIds(myPartialCampaignIds.concat(id)));
        localStorage.setItem(
          'MyPartial',
          myPartialCampaignIds.concat(id).join(',')
        );
      } else {
        if (myPartialCampaignIds.length > 1) {
          clearData();
          dispatch(
            setMyPartialCampaignIds(
              myPartialCampaignIds.filter((itemC) => itemC !== id)
            )
          );
          localStorage.setItem(
            'MyPartial',
            myPartialCampaignIds.filter((itemC) => itemC !== id).join(',')
          );
        } else {
          clearData();
          localStorage.setItem('Filter', 'All');
          dispatch(setCommunityType('All'));
          dispatch(setMyPartialCampaignIds([]));
          localStorage.removeItem('MyPartial');
        }
      }
    },
    [clearData, dispatch, myPartialCampaignIds]
  );

  const communityListRender = useCallback(() => {
    if (!!discord_username && !!campaignList && campaignList.length !== 0) {
      return (
        <div>
          <div className="mx-3 h-px bg-neutral-700"></div>
          <div className="grid grid-cols-1 gap-3 p-3">
            {campaignList.map((item, index) => (
              <div
                key={index}
                onClick={() => {
                  handleSelectCommunityList(item.guild_id.toString());
                }}
                className={classNames({
                  'cursor-pointer rounded-lg hover:bg-neutral-800':
                    myPartialCampaignIds.findIndex(
                      (itemC) => itemC === item.guild_id.toString()
                    ) === -1,
                })}
              >
                <div
                  className={classNames(
                    'flex h-10 items-center gap-2 pl-4 pr-3'
                  )}
                >
                  <div
                    className={classNames(
                      'flex h-4 w-4 shrink-0 items-center justify-center rounded-full',
                      {
                        'border border-neutral-400':
                          myPartialCampaignIds.findIndex(
                            (itemC) => itemC === item.guild_id.toString()
                          ) === -1,
                        'automateBg text-neutral-900':
                          myPartialCampaignIds.findIndex(
                            (itemC) => itemC === item.guild_id.toString()
                          ) !== -1,
                      }
                    )}
                  >
                    {myPartialCampaignIds.findIndex(
                      (itemC) => itemC === item.guild_id.toString()
                    ) !== -1 && <CheckLine size={16} />}
                  </div>
                  <div className="truncate ">{item.name}</div>
                </div>
              </div>
            ))}
          </div>
        </div>
      );
    }
  }, [
    campaignList,
    discord_username,
    handleSelectCommunityList,
    myPartialCampaignIds,
  ]);

  const filterOptionsRender = useCallback(() => {
    if (openFilter) {
      return (
        <div
          className="absolute left-0 top-14 z-50 max-h-[300px] w-[240px] overflow-y-scroll text-base font-normal  text-white filter md:-left-[99px] md:max-h-[494px]"
          onClick={(e) => {
            e.stopPropagation();
          }}
          ref={filterListRef}
        >
          <div className="relative">
            <div
              className={classNames(
                `absolute right-1 hidden h-[140px] w-2 rounded-[49px] bg-neutral-700 top-[${
                  (Number(contentSize?.height) - 140) * Number(scrollPercentage)
                }px]`,
                {
                  '!block': isScrollFilterList,
                }
              )}
              style={{
                top:
                  (Number(contentSize?.height) - 140) *
                  Number(scrollPercentage),
              }}
            ></div>
            <div ref={contentRef}>
              <div className="grid grid-cols-1 gap-3 p-3">
                {filterOptions.map((item, index) => (
                  <div
                    key={index}
                    onClick={() => {
                      handleSelectFilter(item.tag);
                    }}
                    className={classNames({
                      'cursor-pointer': isLogin && discord_username,
                      'cursor-not-allowed text-[#B1B5C3]':
                        (!isLogin || !discord_username) && item?.tag === 'My',
                      'cursor-pointer rounded-lg hover:bg-neutral-800':
                        communityType !== item.tag,
                    })}
                  >
                    <div
                      className={classNames(
                        'flex h-10 items-center gap-2 pl-4 pr-3'
                      )}
                    >
                      <div
                        className={classNames(
                          'flex h-4 w-4 items-center justify-center rounded-full ',
                          {
                            'border border-neutral-400':
                              communityType !== item.tag,
                            'automateBg text-neutral-900':
                              communityType === item.tag,
                          }
                        )}
                      >
                        {communityType === item.tag && <CheckLine size={16} />}
                      </div>
                      {item.text}
                    </div>
                  </div>
                ))}
              </div>
              {communityListRender()}
            </div>
          </div>
        </div>
      );
    }
  }, [
    communityListRender,
    communityType,
    contentSize?.height,
    discord_username,
    handleSelectFilter,
    isLogin,
    isScrollFilterList,
    openFilter,
    scrollPercentage,
  ]);

  return (
    <div
      className="group relative flex h-[42px] w-[42px] shrink-0  items-center justify-center rounded-full"
      onClick={() => {
        toggleFilter();
        setClickFilter(true);
      }}
      ref={filterRef}
    >
      <div
        className={classNames(
          'group peer flex h-10 w-10 items-center justify-center rounded-full border border-neutral-600 ',
          {
            'cursor-pointer hover:border-neutral-100': !clickFilter,
          }
        )}
      >
        <UsersProfiles01
          size={32}
          className={classNames('text-neutral-400', {
            'group-hover:text-neutral-100': !clickFilter,
          })}
        />
      </div>
      {communityType !== 'All' && communityType !== '' && (
        <div className="automateBg absolute -right-1 top-1 h-[16px] w-[16px] rounded-full border-[3px] border-[#141416]"></div>
      )}
      {!clickFilter && (
        <div className="absolute -bottom-14 -left-5 z-20 hidden  w-[80px] rounded-lg border-2 border-neutral-700 bg-neutral-800 px-2 py-3 text-center text-sm font-normal  text-neutral-200 lg:peer-hover:block">
          Filter
        </div>
      )}
      {filterOptionsRender()}
    </div>
  );
};

export default CommunityFilterContent;
