import React, { useCallback, useState } from 'react';

import { CloseMinLine, SearchLine } from 'lib/lucky-x-icons';
import { useDebounceEffect } from 'ahooks';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  setChangeRaffleFilter,
  setIsClearAll,
  setRaffleListCurPage,
  setRaffleListInfo,
  setSearchName,
} from 'store/features/raffleListSlice';
import classNames from 'classnames';

const Search = () => {
  const dispatch = useAppDispatch();
  const { searchName, isClearAll } = useAppSelector(
    (state) => state.raffleList
  );
  const [value, setValue] = useState('');
  const [isFocused, setIsFocused] = useState(false);

  const refleahData = useCallback(() => {
    dispatch(setChangeRaffleFilter(new Date().getTime()));
    dispatch(setRaffleListCurPage(1));
    dispatch(
      setRaffleListInfo({
        has_next: false,
        list: [],
        page: 1,
        size: 20,
        total: -1,
      })
    );
  }, [dispatch]);

  useDebounceEffect(
    () => {
      if (searchName !== value) {
        dispatch(setSearchName(value));
        refleahData();
      }
    },
    [value],
    {
      wait: 500,
    }
  );

  return (
    <div
      className={classNames(
        'flex h-12 items-center gap-2 rounded-3xl   px-3 py-2 md:w-[360px]',
        {
          'border-2 border-primary-500 border-opacity-50': isFocused,
          'border border-neutral-600 hover:border-neutral-500': !isFocused,
        }
      )}
    >
      <SearchLine size={24} className="text-neutral-500" />
      <input
        className=" w-full border-none !bg-[#14171a] text-white placeholder:text-xs placeholder:font-normal placeholder:text-neutral-500 sm:placeholder:text-sm"
        type="text"
        placeholder="Search by community, raffle or project name"
        onChange={(e) => {
          setValue(e.target.value);
          dispatch(setIsClearAll(false));
        }}
        value={isClearAll ? searchName : value}
        onFocus={() => {
          setIsFocused(true);
        }}
        onBlur={() => {
          setIsFocused(false);
        }}
      />
      {!!value && (
        <CloseMinLine
          size={24}
          className="cursor-pointer text-neutral-500"
          onClick={() => {
            setValue('');
            dispatch(setIsClearAll(false));
          }}
        />
      )}
    </div>
  );
};

export default Search;
