import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  useClickAway,
  useScroll,
  useSize,
  useToggle,
  useUpdateEffect,
} from 'ahooks';
import classNames from 'classnames';

import { useAppDispatch, useAppSelector } from 'store/hooks';
import { Filter02 } from 'lib/lucky-x-icons-2';
import { CheckLine } from 'lib/lucky-x-icons';
import {
  setChangeRaffleFilter,
  setMyPartialProjectIds,
  setProjectType,
  setRaffleListCurPage,
  setRaffleListInfo,
} from 'store/features/raffleListSlice';

const filterOptions = [
  {
    id: 1,
    text: 'All projects',
    tag: 'All',
  },
  {
    id: 2,
    text: 'Tracked projects',
    tag: 'Tracked',
  },
];

const ProjectFilterContent = () => {
  const dispatch = useAppDispatch();
  const { projectType, projectList, myParticialProjectIds } = useAppSelector(
    (state) => state.raffleList
  );
  const { screenWidth } = useAppSelector((state) => state.nav);
  const [
    openFilterProject,
    { toggle: toggleFilterProject, setLeft: setCloseFilterProject },
  ] = useToggle(false);
  const [clickFilterProject, setClickFilterProject] = useState(false);
  const filterProjectRef = useRef<HTMLDivElement>(null);

  const filterProjectListRef = useRef<HTMLDivElement>(null);
  const filterProjectListScroll = useScroll(filterProjectListRef);
  const contentRef = useRef<HTMLDivElement>(null);
  const contentSize = useSize(contentRef);

  const [isScrollFilterList, setIsScrollFilterList] = useState(false);

  useClickAway(() => {
    setCloseFilterProject();
    setClickFilterProject(false);
  }, filterProjectRef);

  const scrollPercentage = useMemo(() => {
    if (
      screenWidth >= 768
        ? Number(contentSize?.height) < 494
        : Number(contentSize?.height) < 300
    ) {
      return;
    }
    return (
      Number(filterProjectListScroll?.top) /
      (Number(contentSize?.height) - (screenWidth >= 768 ? 492 : 298))
    );
  }, [contentSize?.height, filterProjectListScroll?.top, screenWidth]);

  useEffect(() => {
    if (filterProjectListScroll?.top !== 0) {
      setIsScrollFilterList(true);
    }
  }, [filterProjectListScroll?.top]);

  useUpdateEffect(() => {
    if (isScrollFilterList) {
      setTimeout(() => {
        setIsScrollFilterList(false);
      }, 1000);
    }
  }, [isScrollFilterList]);

  const clearData = useCallback(() => {
    dispatch(setRaffleListCurPage(1));
    dispatch(
      setRaffleListInfo({
        has_next: false,
        list: [],
        page: 1,
        size: 20,
        total: -1,
      })
    );
    setClickFilterProject(false);
    dispatch(setChangeRaffleFilter(new Date().getTime()));
  }, [dispatch]);

  const handleSelectFilter = useCallback(
    (tag: string) => {
      if (tag !== projectType) {
        clearData();
        dispatch(setMyPartialProjectIds([]));
        dispatch(setProjectType(tag));
        localStorage.setItem('FilterProject', tag);
        setCloseFilterProject();
      }
    },
    [clearData, dispatch, projectType, setCloseFilterProject]
  );

  const handleSelectProjectList = useCallback(
    (id: string) => {
      dispatch(setProjectType('my_partial'));
      clearData();
      localStorage.setItem('FilterProject', 'my_partial');
      if (myParticialProjectIds.findIndex((itemC) => itemC === id) === -1) {
        dispatch(setMyPartialProjectIds(myParticialProjectIds.concat(id)));
        localStorage.setItem(
          'MyPartialProject',
          myParticialProjectIds.concat(id).join(',')
        );
      } else {
        if (myParticialProjectIds.length > 1) {
          clearData();
          dispatch(
            setMyPartialProjectIds(
              myParticialProjectIds.filter((itemC) => itemC !== id)
            )
          );
          localStorage.setItem(
            'MyPartialProject',
            myParticialProjectIds.filter((itemC) => itemC !== id).join(',')
          );
        } else {
          clearData();
          localStorage.setItem('FilterProject', 'All');
          dispatch(setProjectType('All'));
          dispatch(setMyPartialProjectIds([]));
          localStorage.removeItem('MyPartialProject');
        }
      }
    },
    [clearData, dispatch, myParticialProjectIds]
  );

  const projectListRender = useCallback(() => {
    if (!!projectList && projectList.length !== 0) {
      return (
        <div>
          <div className="mx-3 h-px bg-neutral-700"></div>
          <div className="grid grid-cols-1 gap-3 p-3">
            {projectList.map((item, index) => (
              <div
                key={index}
                onClick={() => {
                  handleSelectProjectList(item.id.toString());
                }}
                className={classNames({
                  'cursor-pointer rounded-lg hover:bg-neutral-800':
                    myParticialProjectIds.findIndex(
                      (itemC) => itemC === item.id.toString()
                    ) === -1,
                })}
              >
                <div
                  className={classNames(
                    'flex h-10 items-center gap-2 pl-4 pr-3'
                  )}
                >
                  <div
                    className={classNames(
                      'flex h-4 w-4 shrink-0 items-center justify-center rounded-full',
                      {
                        'border border-neutral-400':
                          myParticialProjectIds.findIndex(
                            (itemC) => itemC === item.id.toString()
                          ) === -1,
                        'automateBg text-neutral-900':
                          myParticialProjectIds.findIndex(
                            (itemC) => itemC === item.id.toString()
                          ) !== -1,
                      }
                    )}
                  >
                    {myParticialProjectIds.findIndex(
                      (itemC) => itemC === item.id.toString()
                    ) !== -1 && <CheckLine size={16} />}
                  </div>
                  <div className="truncate ">{item.name}</div>
                </div>
              </div>
            ))}
          </div>
        </div>
      );
    }
  }, [handleSelectProjectList, myParticialProjectIds, projectList]);

  const filterOptionsRender = useCallback(() => {
    if (openFilterProject) {
      return (
        <div
          className="absolute left-0 top-14 z-50 max-h-[300px] w-[240px] overflow-y-scroll text-base font-normal  text-white filter md:-left-[99px] md:max-h-[494px]"
          onClick={(e) => {
            e.stopPropagation();
          }}
          ref={filterProjectListRef}
        >
          <div className="relative">
            <div
              className={classNames(
                `absolute right-1 hidden h-[140px] w-2 rounded-[49px] bg-neutral-700 top-[${
                  (Number(contentSize?.height) - 140) * Number(scrollPercentage)
                }px]`,
                {
                  '!block': isScrollFilterList,
                }
              )}
              style={{
                top:
                  (Number(contentSize?.height) - 140) *
                  Number(scrollPercentage),
              }}
            ></div>
            <div ref={contentRef}>
              <div className="grid grid-cols-1 gap-3 p-3">
                {filterOptions.map((item, index) => (
                  <div
                    key={index}
                    onClick={() => {
                      handleSelectFilter(item.tag);
                    }}
                    className={classNames({
                      'cursor-pointer rounded-lg hover:bg-neutral-800':
                        projectType !== item.tag,
                    })}
                  >
                    <div
                      className={classNames(
                        'flex h-10 items-center gap-2 pl-4 pr-3'
                      )}
                    >
                      <div
                        className={classNames(
                          'flex h-4 w-4 items-center justify-center rounded-full ',
                          {
                            'border border-neutral-400':
                              projectType !== item.tag,
                            'automateBg text-neutral-900':
                              projectType === item.tag,
                          }
                        )}
                      >
                        {projectType === item.tag && <CheckLine size={16} />}
                      </div>
                      {item.text}
                    </div>
                  </div>
                ))}
              </div>
              {projectListRender()}
            </div>
          </div>
        </div>
      );
    }
  }, [
    contentSize?.height,
    handleSelectFilter,
    isScrollFilterList,
    openFilterProject,
    projectListRender,
    projectType,
    scrollPercentage,
  ]);

  return (
    <div
      className="group relative flex h-[42px] w-[42px] shrink-0 items-center justify-center rounded-full"
      onClick={() => {
        toggleFilterProject();
        setClickFilterProject(true);
      }}
      ref={filterProjectRef}
    >
      <div
        className={classNames(
          'group peer flex h-10 w-10 items-center justify-center rounded-full border border-neutral-600 ',
          {
            'cursor-pointer hover:border-neutral-100': !clickFilterProject,
          }
        )}
      >
        <Filter02
          size={32}
          className={classNames('text-neutral-400', {
            'group-hover:text-neutral-100': !clickFilterProject,
          })}
        />
      </div>
      {projectType !== 'All' && projectType !== '' && (
        <div className="automateBg absolute -right-1 top-1 h-[16px] w-[16px] rounded-full border-[3px] border-[#141416]"></div>
      )}
      {!clickFilterProject && (
        <div className="absolute -bottom-14 -left-5 z-20 hidden  w-[80px] rounded-lg border-2 border-neutral-700 bg-neutral-800 px-2 py-3 text-center text-sm font-normal  text-neutral-200 lg:peer-hover:block">
          Filter
        </div>
      )}
      {filterOptionsRender()}
    </div>
  );
};

export default ProjectFilterContent;
