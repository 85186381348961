import React, { useCallback } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  setChangeRaffleFilter,
  setCommunityType,
  setFreeMint,
  setIsClearAll,
  setMyPartialCampaignIds,
  setMyPartialProjectIds,
  setProjectType,
  setRaffleListCurPage,
  setRaffleListInfo,
  setSearchName,
  setSortType,
} from 'store/features/raffleListSlice';
import { X03 } from 'lib/lucky-x-icons-2';

const Options = () => {
  const dispatch = useAppDispatch();
  const {
    communityType,
    campaignList,
    myPartialCampaignIds,
    projectType,
    projectList,
    myParticialProjectIds,
  } = useAppSelector((state) => state.raffleList);

  const handleClearData = useCallback(() => {
    dispatch(setChangeRaffleFilter(new Date().getTime()));
    dispatch(setRaffleListCurPage(1));
    dispatch(
      setRaffleListInfo({
        has_next: false,
        list: [],
        page: 1,
        size: 20,
        total: -1,
      })
    );
  }, [dispatch]);

  const handleClearAll = useCallback(() => {
    localStorage.setItem('Filter', 'All');
    dispatch(setCommunityType('All'));
    localStorage.setItem('FilterProject', 'All');
    dispatch(setProjectType('All'));
    localStorage.setItem('SortBy', 'Trending');
    dispatch(setSortType('Trending'));
    localStorage.setItem('FreeMint', '0');
    dispatch(setFreeMint('0'));
    localStorage.removeItem('MyPartial');
    localStorage.removeItem('MyPartialProject');
    dispatch(setMyPartialCampaignIds([]));
    dispatch(setMyPartialProjectIds([]));
    dispatch(setSearchName(''));
    dispatch(setIsClearAll(true));
    handleClearData();
  }, [dispatch, handleClearData]);

  const handleClearCommunity = useCallback(
    (id?: string) => {
      if (communityType === 'My') {
        localStorage.setItem('Filter', 'All');
        dispatch(setCommunityType('All'));
        handleClearData();
      } else {
        if (myPartialCampaignIds.length > 1) {
          dispatch(
            setMyPartialCampaignIds(
              myPartialCampaignIds.filter((itemC) => itemC !== id)
            )
          );
          localStorage.setItem(
            'MyPartial',
            myPartialCampaignIds.filter((itemC) => itemC !== id).join(',')
          );
          handleClearData();
        } else {
          localStorage.setItem('Filter', 'All');
          dispatch(setCommunityType('All'));
          dispatch(setMyPartialCampaignIds([]));
          localStorage.removeItem('MyPartial');
          handleClearData();
        }
      }
    },
    [communityType, dispatch, handleClearData, myPartialCampaignIds]
  );

  const handleClearProject = useCallback(
    (id?: string) => {
      if (projectType === 'Tracked') {
        localStorage.setItem('FilterProject', 'All');
        dispatch(setProjectType('All'));
        handleClearData();
      } else {
        if (myParticialProjectIds.length > 1) {
          dispatch(
            setMyPartialProjectIds(
              myParticialProjectIds.filter((itemC) => itemC !== id)
            )
          );
          localStorage.setItem(
            'MyPartialProject',
            myParticialProjectIds.filter((itemC) => itemC !== id).join(',')
          );
          handleClearData();
        } else {
          localStorage.setItem('FilterProject', 'All');
          dispatch(setProjectType('All'));
          dispatch(setMyPartialProjectIds([]));
          localStorage.removeItem('MyPartialProject');
          handleClearData();
        }
      }
    },
    [dispatch, handleClearData, myParticialProjectIds, projectType]
  );

  const communityRender = useCallback(() => {
    if (communityType === 'My') {
      return (
        <div className=" flex flex-wrap items-center gap-1 rounded-[32px] bg-neutral-900 px-4 py-2">
          <div className="text-neutral-300  ">Community:</div>
          <div className="flex items-center gap-1">
            <span>My communities</span>
            <X03
              size={24}
              className="cursor-pointer text-neutral-400 hover:text-neutral-100"
              onClick={() => {
                handleClearCommunity();
              }}
            />
          </div>
        </div>
      );
    } else {
      return (
        <div className="flex max-w-full flex-wrap items-center gap-4 truncate">
          {myPartialCampaignIds.map((item, index) => (
            <div
              className=" flex  max-w-full items-center gap-1 rounded-[32px] bg-neutral-900 px-4 py-2"
              key={index}
            >
              <div className="text-neutral-300  ">Community:</div>
              <div className="flex max-w-full flex-row-reverse items-center gap-1 truncate">
                <X03
                  size={24}
                  className="shrink-0 cursor-pointer text-neutral-400 hover:text-neutral-100"
                  onClick={() => {
                    handleClearCommunity(item);
                  }}
                />
                <div className="w-full truncate">
                  {
                    campaignList?.find(
                      (itemC) => String(itemC?.guild_id) === item
                    )?.name
                  }
                </div>
              </div>
            </div>
          ))}
        </div>
      );
    }
  }, [campaignList, communityType, handleClearCommunity, myPartialCampaignIds]);

  const projectRender = useCallback(() => {
    if (projectType === 'Tracked') {
      return (
        <div className=" flex flex-wrap items-center gap-1 rounded-[32px] bg-neutral-900 px-4 py-2">
          <div className="text-neutral-300  ">Project:</div>
          <div className="flex items-center gap-1">
            <span>Tracked projects</span>
            <X03
              size={24}
              className="cursor-pointer text-neutral-400 hover:text-neutral-100"
              onClick={() => {
                handleClearProject();
              }}
            />
          </div>
        </div>
      );
    } else {
      return (
        <div className="flex max-w-full flex-wrap items-center gap-4 truncate">
          {myParticialProjectIds.map((item, index) => (
            <div
              className=" flex  max-w-full items-center gap-1 rounded-[32px] bg-neutral-900 px-4 py-2"
              key={index}
            >
              <div className="text-neutral-300  ">Project:</div>
              <div className="flex max-w-full flex-row-reverse items-center gap-1 truncate">
                <X03
                  size={24}
                  className="shrink-0 cursor-pointer text-neutral-400 hover:text-neutral-100"
                  onClick={() => {
                    handleClearProject(item);
                  }}
                />
                <div className="w-full truncate">
                  {
                    projectList?.find((itemC) => String(itemC?.id) === item)
                      ?.name
                  }
                </div>
              </div>
            </div>
          ))}
        </div>
      );
    }
  }, [handleClearProject, myParticialProjectIds, projectList, projectType]);

  if (
    (communityType === 'All' || communityType === '') &&
    (projectType === 'All' || projectType === '')
  ) {
    return null;
  }

  return (
    <div className=" mt-6 flex flex-wrap items-center gap-4 text-base font-medium text-neutral-100 ">
      {communityRender()}
      {projectRender()}
      <div
        className="flex h-10 w-[94px] cursor-pointer items-center justify-center rounded-full border border-neutral-600 px-4 text-sm font-medium hover:border-neutral-100 active:border-neutral-100 active:bg-neutral-900"
        onClick={handleClearAll}
      >
        Clear all
      </div>
    </div>
  );
};

export default Options;
