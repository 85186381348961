import React, { useCallback, useRef, useState } from 'react';
import { useClickAway, useToggle } from 'ahooks';
import classNames from 'classnames';
import { CheckLine } from 'lib/lucky-x-icons';

import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  setChangeRaffleFilter,
  setRaffleListCurPage,
  setRaffleListInfo,
  setSortType,
} from 'store/features/raffleListSlice';
import { SortVertical02 } from 'lib/lucky-x-icons-2';

const sortByOptions = [
  {
    id: 1,
    text: 'Trending',
    tag: 'Trending',
  },
  {
    id: 2,
    text: 'Newest',
    tag: 'Newest',
  },
  {
    id: 3,
    text: 'End soon',
    tag: 'End Soon',
  },
];

const SortByContent = () => {
  const dispatch = useAppDispatch();
  const sortByRef = useRef<HTMLDivElement>(null);
  const { sortType } = useAppSelector((state) => state.raffleList);
  const [openSortBy, { toggle: toggleSortBy, setLeft: setCloseSortBy }] =
    useToggle(false);
  const [clickSortBy, setClickSortBy] = useState(false);

  useClickAway(() => {
    setCloseSortBy();
    setClickSortBy(false);
  }, sortByRef);

  const handleSelectSortBy = useCallback(
    (tag: string) => {
      if (tag !== sortType) {
        dispatch(setRaffleListCurPage(1));
        dispatch(
          setRaffleListInfo({
            has_next: false,
            list: [],
            page: 1,
            size: 20,
            total: -1,
          })
        );
        dispatch(setSortType(tag));
        localStorage.setItem('SortBy', tag);
        dispatch(setChangeRaffleFilter(new Date().getTime()));
        setCloseSortBy();
        setClickSortBy(false);
      }
    },
    [dispatch, setCloseSortBy, sortType]
  );

  const sortByOptionsRender = useCallback(() => {
    if (openSortBy) {
      return (
        <div
          className="absolute -left-[99px] top-14 z-50 w-[240px] text-base font-normal text-white filter"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <div className="grid grid-cols-1 gap-3 p-3">
            {sortByOptions.map((item, index) => (
              <div
                key={index}
                onClick={() => {
                  handleSelectSortBy(item.tag);
                }}
                className={classNames({
                  'cursor-pointer rounded-lg hover:bg-neutral-800':
                    sortType !== item.tag,
                })}
              >
                <div
                  className={classNames(
                    'flex h-10 items-center gap-2 pl-4 pr-3'
                  )}
                >
                  <div
                    className={classNames(
                      'flex h-4 w-4 items-center justify-center rounded-full ',
                      {
                        'border border-neutral-400': sortType !== item.tag,
                        'automateBg text-neutral-900': sortType === item.tag,
                      }
                    )}
                  >
                    {sortType === item.tag && <CheckLine size={16} />}
                  </div>
                  {item.text}
                </div>
              </div>
            ))}
          </div>
        </div>
      );
    }
  }, [handleSelectSortBy, openSortBy, sortType]);

  return (
    <div
      className="group relative flex h-[42px] w-[42px] shrink-0  cursor-pointer items-center justify-center rounded-full"
      onClick={() => {
        toggleSortBy();
        setClickSortBy(true);
      }}
      ref={sortByRef}
    >
      <div
        className={classNames(
          'group peer flex h-10 w-10 items-center justify-center rounded-full border border-neutral-600',
          {
            'hover:border-neutral-100': !clickSortBy,
          }
        )}
      >
        <SortVertical02
          size={32}
          className={classNames('text-neutral-400', {
            'group-hover:text-neutral-100': !clickSortBy,
          })}
        />
      </div>
      {sortType !== 'Trending' && sortType !== '' && (
        <div className="automateBg absolute -right-1 top-1 h-[16px] w-[16px] rounded-full border-[3px] border-[#141416]"></div>
      )}
      {!clickSortBy && (
        <div className="absolute -bottom-14 -left-5 z-20 hidden w-[80px] rounded-lg border-2 border-neutral-700 bg-neutral-800 px-2 py-3 text-center text-sm  font-normal text-neutral-200 lg:peer-hover:block">
          Sort by
        </div>
      )}
      {sortByOptionsRender()}
    </div>
  );
};

export default SortByContent;
